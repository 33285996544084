import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoCloudDownloadOutline } from 'react-icons/io5'
import { audio1, audio2, audio4, audio5, audio6, audio7, audio8, audio9, audio10, audio11 } from '../../../assets'
import './form.scss'


const Form = () => {
  return (
    <div className='content-wrapper'>
        <div className='box-title'>
            <div className=''>
                <h2 className='teks-decoration green'>Formulir Gugatan</h2>
                <h5 className='teks-decoration'>Formulir Surat Gugatan Pengadilan Agama Tangerang</h5>
            </div>
        </div>
        <div className='box-form'>
            <Container> 
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Cerai Talak</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio1 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/1cQNp5bMTNFPVj6286YQRM862rBkJH8ac/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Cerai Talak dan Hak Asuh Anak</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio2 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/1pBsaWkaS7pz8Re4lpPgnncxt3qlnqIl0/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Cerai Talak dan Harta Bersama</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio4 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/1MprDswpMrBnLucmEZQpLcEZeUBq23xif/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Cerai Talak Ghaib</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio5 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/13YEZOcZAl-VTbAFrhBiEWYIOOFqGI4AX/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Cerai Gugat</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio6 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/1drHaTmbcrQ8flNKLjUvHPinfN63Nsbun/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Cerai Gugat dan Hak Asuh Anak</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio7 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/1SN7iK0b2xV_krgDVTvlWtOK8iF14KtNK/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Cerai Gugat, Hak Asuh Anak dan Nafkah Anak</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio8 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/1WWxWW2xVeEVgYfhdqv_wEJ5p54HFqSPa/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Cerai Gugat Ghaib</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio9 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/19g_tipAugbKgChuGL9jKkxCbaKKWD5cI/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Permohonan Pengesahan Perkawinan/Itsbat Nikah</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio10 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/1-SIhKnruYCiynhlr4m_Oa_uIxS0vvAW-/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="margin-audio">
                        <div className='box-form-box'>
                            <div className='box-form-box-left'>
                                <p>Blanko Pendaftaran Permohonan Penetapan Ahli Waris (PAW)</p>
                                <audio controls className='audio-control'>
                                    <source src={ audio11 } type="audio/mpeg"/>
                                </audio>
                            </div>
                            <div className='box-form-box-right'>
                                <a href="https://docs.google.com/document/d/1uwOJlSMuOP_sVYZlEq7B6lKSgLJcJsr9/edit?usp=share_link&ouid=115247661375947688029&rtpof=true&sd=true" className='a-href'>
                                    <div className='circle-unduh'>
                                        <IoCloudDownloadOutline className='icon-unduh'/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container> 
        </div>
    </div>
  )
}

export default Form
