import React from 'react'
import { Container, Col, Row } from 'react-bootstrap' 
import { Link } from 'react-router-dom'
import { iAudio, iBadilag, iForm, imgTop, iPa, iVideo } from '../../../assets'
import './home.scss'

const Home = () => {
  return (
    <div className='bg-top'>
      <div className='box-margin-top'>
        <div className='content-wrapper'>
          <Container> 
            <Row>
              <Col md>
                <div className='box-img-top'>
                  <img className="img-top" src={ imgTop } alt="First slide"/>
                </div>
              </Col>
              <Col md>
                <div className='green box-title-home'>
                  <div className='box-box-title'>
                    <h1 className='teks-bold'>E-GUMA</h1>
                    <h4 className='teks-bold'>Elektronik Gugatan Mandiri</h4>
                    <p className='justify-text-box-title'>Pengadilan Agama Tangerang terus berkomitmen untuk selalu memberikan pelayanan prima kepada masyarakat, termasuk kelompok rentan (Lansia, Perempuan, Anak, Penyandang Disabilitas). kini kami telah hadir melalui website pembuatan surat gugatan/permohonan secara mandiri.</p>
                  </div> 
                </div>          
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className='box-margin light-grey top'>
        <div className='content-wrapper'>
          <Container>
            <Row>
              <Col xs={6} md={3} lg={2}>
                <Link to="/audio-book" className='a-href'>
                  <div className='box-circle'>
                    <div className='circle'>
                      <img className="icon-style" src={ iAudio }/>
                    </div>
                    <p className='text-p'>Audio Book Penggunaan Aplikasi Gugatan Mandiri</p>
                  </div>
                </Link>
              </Col>
              <Col xs={6} md={3} lg={2}>
                <Link to="/video-player" className='a-href'>
                  <div className='box-circle'>
                    <div className='circle'>
                      <img className="icon-style" src={ iVideo }/>
                    </div>
                    <p className='text-p'>Video Tutorial Penggunaan Aplikasi Gugatan Mandiri</p>
                  </div>
                </Link>
              </Col>
              <Col xs={6} md={3} lg={2}>
                <Link to="/form" className='a-href'>
                  <div className='box-circle'>
                    <div className='circle'>
                      <img className="icon-style" src={ iForm }/>
                    </div>
                    <p className='text-p'>Formulir Surat Gugatan Pengadilan Agama Tangerang</p>
                  </div>
                </Link>
              </Col>
              <Col xs={6} md={3} lg={2}>
                <a href="https://pa-tangerangkota.go.id/" className='a-href'>
                  <div className='box-circle'>
                    <div className='circle'>
                      <img className="icon-style" src={ iBadilag }/>
                    </div>
                    <p className='text-p'>Website Pengadilan Agama Tangerang</p>
                  </div>
                </a>
              </Col>
              <Col xs={6} md={3} lg={2}>
                <a href="http://gugatanmandiri.badilag.net/" className='a-href'>
                  <div className='box-circle'>
                    <div className='circle'>
                      <img className="icon-style" src={ iPa }/>
                    </div>
                    <p className='text-p'>Aplikasi Gugatan Mandiri BADILAG</p>
                  </div>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Home
