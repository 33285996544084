import React from 'react'
import { Navbar, Container } from 'react-bootstrap'
import './header.scss'
import { logo, logoPa } from '../../../assets'

const Header = () => {
  return (
    <div>
        <Navbar className='bg-header'>
            <Container>
              <Navbar.Brand href="/">
                <img className="bg-logo-pa" src={ logoPa }/>
              </Navbar.Brand>
              <Navbar.Brand href="/" className=''>
                <img className="bg-logo" src={ logo }/>   
              </Navbar.Brand>
            </Container>
        </Navbar>
    </div>
  )
}

export default Header
