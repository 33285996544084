import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { imgAudioBook } from '../../../assets'
import { useHistory } from "react-router-dom";
import './audiobook.scss'

const AudioBook = () => {
  const history = useHistory()
  return (
    <div className=''>
      <div className='box-title'>
        <div className='content-wrapper'>
          <h2 className='teks-decoration green'>Audio Book</h2>
          <h4 className='teks-decoration'>Penggunaan Aplikasi Gugatan Mandiri</h4>
        </div>
      </div>

      <div className='fp-player'>
        <div className='content-wrapper'>
          <iframe width="100%" height="500" title="iframe-audio-book" class="fp-iframe" src="https://heyzine.com/flip-book/9cb6c6f484.html" allowfullscreen></iframe>
        </div>
      </div>

      <div className='box-margin'>
        <div className='box-audio content-wrapper'>
          <Container> 
            <Row>
              <Col md>
                <div className='box-box-audio'>
                  <img className="img-audiobook" src={ imgAudioBook } alt="First slide"/>
                </div>
              </Col>
              <Col md>
                <div className='box-audiobook' onClick={() => {history.push("/video-player")}}>
                  <h1 className='teks-bold'>Scan Me!</h1>
                  <h3 className='teks-bold'>Putar Audio Book di Smartphone</h3>
                  <div className='qr-code-box'>
                    <a href="https://heyzine.com/flip-book/9cb6c6f484.html">
                      <img src="https://heyzine.com/flip-book/qr-code/9cb6c6f484-583166.png" alt="flipbook QR Code" className='qr-code'/>
                    </a>
                  </div>
                </div>           
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default AudioBook
