import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Footer from '../../../components/molecules/Footer'
import Header from '../../../components/molecules/Header'
import AudioBook from '../AudioBook'
import Form from '../Form'
import Home from '../Home'
import VideoPlayer from '../VideoPlayer'
import './mainAppUser.scss'

const MainAppUser = () => {
    return (
        <div className="wrapper">
            <Router>
            <div className="header-wrapper">
                <Header />
            </div>
            <div className="">
                <Switch>
                    <Route path="/audio-book">
                        <AudioBook />
                    </Route>
                    <Route path="/video-player">
                        <VideoPlayer />
                    </Route>
                    <Route path="/form">
                        <Form />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </div>
            <div>
                <Footer />
            </div>
            </Router>
        </div>
    )
}

export default MainAppUser
